import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import Home from '@pages/home';
import Error from '@pages/error';

import { withApollo } from '../graphql/apollo';

import { withTranslation, i18n } from '../i18n';

import homeQuery from '../graphql/queries/home.graphql';

const {
  publicRuntimeConfig: { ACTIVE_SITE_HANDLE }
} = getConfig();

const IndexPage = ({ data, site }) =>
  data && data.entry ? <Home site={site} data={data} /> : <Error statusCode={404} site={site} />;

IndexPage.getInitialProps = async ctx => {
  const { req, apolloClient } = ctx;

  const { language } = req || i18n;
  const site = `${ACTIVE_SITE_HANDLE}${language.toUpperCase()}`;

  const { data, error } = await apolloClient.query({
    query: homeQuery,
    variables: { site }
  });

  if ((data && !data.entry) || error) {
    if (ctx.res) {
      ctx.res.statusCode = 404;
    }
  }

  return {
    namespacesRequired: ['common'],
    site,
    data
  };
};

IndexPage.propTypes = {
  site: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired
};

IndexPage.defaultProps = {};

export default withApollo(withTranslation('common')(IndexPage));
